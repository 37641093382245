
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }
  
