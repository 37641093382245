@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,400;1,500;1,600;1,700;1,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  font-family: Poppins, sans-serif;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

}

code {
  font-family: 'Poppins, sans-serif'
}

p,label{
  margin: 0;
  font-size: 13px;
}


.grayGradient{

  background: #f7f2f2;
  background: -webkit-linear-gradient(0deg, #f7f2f2 0%, #eeeeee 100%);
  background: linear-gradient(0deg, #f7f2f2 0%, #eeeeee 100%);

}
.blueGradient{

  opacity: 0.1;
  background: linear-gradient(180deg, #494AF8 0%, rgba(73, 74, 248, 0.00) 100%);

}
.spacingDiv{

  padding: 0 60px;
}

h1,h2,h3,h4{
font-size: 30px;
  
}

a{
  cursor: pointer;
}

body ::-webkit-scrollbar {
  width: 0.5em;
  padding:10px
}

body ::-webkit-scrollbar-thumb {
  background: #CCCCCC;
  outline: none;
  border-radius:10px;
  
}

 
  @keyframes blinking{  
  0%{   color: #22c55e ;   }  
  47%{   color: #22c55e ; }  
  62%{   color: #fff; }  
  97%{   color:#fff; }  
  100%{  color: #fff;   }  
  }  

.blink-color {
  animation:blinking 1.5s infinite;  
}

@media screen and (max-width:700px){

  .spacingDiv{

    padding: 0 15px;

  }

}
